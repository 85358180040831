import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import PubSub from "pubsub-js";
var setAnalyticsDataLayer = require("../../lib/analytics-datalayer.js");
import { useAuth } from "../../Auth";
export default function SearchResultsDetail(props) {
    var articleUri = useParams().articleUri;
    var articleSrc = encodeURI(decodeURI(articleUri !== null && articleUri !== void 0 ? articleUri : ''));
    var auth = useAuth();
    useEffect(function () {
        var breadcrumbAnalytics = {
            primaryCategory: "Search results details",
            subCategory: "Search results details"
        };
        setAnalyticsDataLayer.default('Search results details', auth.profile.uid, auth.profile.lanID);
        PubSub === null || PubSub === void 0 ? void 0 : PubSub.publish('analytics.PageLoad', 'window.digitalData');
    }, []);
    return (_jsx(_Fragment, { children: _jsx("iframe", { src: articleSrc, width: "99.5%", height: "99.2%", frameBorder: "0", style: { border: 0, padding: 0 } }) }));
}
