function setAnalyticsDataLayer(pageName, userId, lanId, breadcrumb = {}) {
    let pageInfoObj = {
        pageName: 'us:myexperianinternal:' + pageName,
        pageTitle: pageName,
        pageURL: window.location.href
    }
    let digitalData = {
        page: {
            pageInfo: pageInfoObj
        },
        user: {
            profileInfo: {
                userUUID: userId,
                userID: lanId
            }
        },
        error: []
    }
    if (breadcrumb.primaryCategory) {
        digitalData.page.category = {
            primaryCategory: breadcrumb.primaryCategory,
            subCategory: breadcrumb.subCategory,
            categoryType: "Tile Page",
        }
    }
    window.digitalData = digitalData;
}
export default setAnalyticsDataLayer;