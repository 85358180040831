var configTxt = require("../../../config-en.json");
export var removeL1FromShortDecription = function (desc) {
    var regex = /[lL]{1}[0-9][_]/;
    return desc === null || desc === void 0 ? void 0 : desc.replace(regex, '');
};
export var stringStartsWithL1 = function (desc) {
    return desc.startsWith("L1_");
};
export var numericTextOnly = function (text) {
    var regex = /^[0-9]+$/;
    return regex.test(text);
};
export var alphaNumericTextOnly = function (text) {
    var regex = /^[a-zA-Z0-9]*$/;
    return regex.test(text);
};
export var alphaNumericWithSpaceTextOnly = function (text) {
    var regex = /^[a-z\d\s]+$/i;
    return regex.test(text);
};
export var lowercaseAlphaNumericWithDashTextOnly = function (text) {
    var regex = /^[a-z0-9\n\r-]*$/;
    return regex.test(text);
};
export var numericTextWithCommaSepratted = function (text) {
    var regex = /^[0-9.,\b]+$/;
    return regex.test(text);
};
export var validateUrlLink = function (url) {
    var urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
    return urlRegex.test(url);
};
export var validateDomain = function (value) {
    var regex = /^((?![\.-])[a-zA-Z0-9-_\.]).(?![\.])[a-zA-Z0-9-_\.]+(\.[a-zA-Z0-9_]+)$/;
    if (regex.test(value)) {
        return { isError: false, errorMsg: "" };
    }
    else {
        return { isError: true, errorMsg: configTxt.DOMAIN_ERR_MSG };
    }
};
export var validateEmailId = function (value) {
    var regex = /^(?![\.])[a-zA-Z0-9._-]+(?<![.]+)@(?![\.-])[a-zA-Z0-9-_\.]+(\.[a-zA-Z0-9_]+)$/;
    return regex.test(value);
};
export var productDropDownData = {
    options: [
        { id: 0, name: "Select", value: "" },
        { id: 1, name: "PowerCurve Originations Advanced / Pro", value: "powercurve_originations_advanced_pro" },
        { id: 2, name: "PowerCurve Originations Essentials", value: "powercurve_originations_essentials" },
        { id: 3, name: "PowerCurve Collections Advanced / Pro", value: "powercurve_collections_advanced_pro" },
        { id: 4, name: "PowerCurve Collections Essentials", value: "powercurve_collections_essentials" },
        { id: 5, name: "PowerCurve Collections Healthcare", value: "powercurve_collections_healthcare" },
        { id: 6, name: "CrossCore", value: "crosscore" },
        { id: 7, name: "PCSM As a service", value: "pcsm_as_a_service" }
    ]
};
export var regionSponsoringPartnerDropDownData = {
    options: [
        { id: 0, name: "Global", value: "global" },
        { id: 1, name: "Brazil", value: "brazil" },
        { id: 2, name: "UK&I", value: "uk" },
        { id: 3, name: "North America", value: "north_america" },
        { id: 4, name: "EMEA & APAC", value: "splatam" },
        { id: 5, name: "Spanish LATAM", value: "splatam" },
    ]
};
export var intakeRejectedDropDownData = {
    options: [
        { id: 0, name: "Partner not interested", value: "partner_not_interested" },
        { id: 1, name: "Not good fit", value: "not_good_fit" },
        { id: 2, name: "Others", value: "other" },
    ]
};
export var typeOfSalesDropDownData = {
    options: [
        { id: 0, name: "Select", value: "" },
        { id: 1, name: "Batch", value: "batch" },
        { id: 2, name: "Hybrid", value: "hybrid" },
        { id: 3, name: "Online", value: "online" }
    ]
};
export var allCrossCoreProducts = ["Fraud Risk", "Precise ID", "Neuro ID", "FraudNet", "Digital Risk Score", "DeviceInsight", "CrossCore-Partnerships", "CrossCore Platform", "CrossCore", "BizID"];
export var defaultChooseProductItemsUK = ["Credit Card", "Auto", "Mortgage", "Home Equity", "Personal Lending", "Small Business"];
export var defaultChoosePurposesItemsUK = ["Credit Card", "Auto", "Mortgage", "Home Equity", "Personal Lending", "Small Business"];
// export const defaultChooseSubPurposesItemsUK = ["Pre-Screen", "ITA", "Line Assignment"];
export var defaultChooseSubPurposesItemsUK = ["Delphi ML", "Delphi Gen11", "Fraud", "Custom", "Semi-Custom"];
export var defaultChoosePartnersItemsUK = ["Banking Affiliate 1", "Banking Affiliate 2", "Co-Brand 1", "Co-Brand 2"];
export var defaultChooseProductItemsEMAP = ["Auto loans", "Consumer loans", "Mortgage", "Credit Cards", "Personal loans", "Commercial loans", "Revolving credit", "Business loans", "Secured loans"];
export var commonRegions = {
    options: [
        { id: 0, name: "Select", value: "" },
        { id: 1, name: "All regions", value: "All regions" },
        { id: 2, name: "Brazil", value: "Brazil" },
        { id: 3, name: "UK&I", value: "UK&I" },
        { id: 4, name: "North America", value: "North America" },
        { id: 5, name: "EMEA & APAC", value: "EMEA & APAC" },
        { id: 6, name: "Spanish LATAM", value: "Spanish LATAM" }
    ]
};
export var AdvanceSearchRegions = {
    options: [
        { id: 0, name: "Select", value: "" },
        { id: 1, name: "All regions", value: "All regions" },
        { id: 2, name: "APAC", value: "APAC" },
        { id: 3, name: "EMEA", value: "EMEA" },
        { id: 4, name: "North America", value: "North America" },
        { id: 5, name: "Latin America", value: "Latin America" },
        { id: 6, name: "UK&I", value: "UK&I" }
    ]
};
