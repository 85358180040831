import PubSub from "pubsub-js";
function logErrorToAnalyticsDataLayer(err, impacted) {    
    let errorLog = {
        errorID: err?.response?.status || err?.code,
        errorMessage: err?.response?.data?.statusText || err?.message,
        errorType: impacted
    }
   window.digitalData?.error?.push(errorLog);
   PubSub?.publish("analytics.error", "window.digitalData");
}
export default logErrorToAnalyticsDataLayer;