var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import { Avatar, DiscButton, Input, InputAddon, Menu, MenuItem, Popover, PrimaryHeader, PrimaryHeaderTab, PrimaryHeaderTabs, Text } from "@wayfarer/components";
import { Outlet, useLocation, Link } from "react-router-dom";
import { useAuth } from "./Auth";
import { ExitToApp, Search, Cancel } from "@material-ui/icons";
import { useNavigate } from "react-router-dom";
import __checkRoleBasedAccess from "./RBA";
export default function Shell() {
    var main = useRef(null);
    var search = useLocation().search;
    useEffect(function () {
        var _a;
        (_a = main === null || main === void 0 ? void 0 : main.current) === null || _a === void 0 ? void 0 : _a.scrollTo({ top: 0 });
    }, [search]);
    var auth = useAuth();
    var location = useLocation();
    var _a = useState(false), avatarMenuOpen = _a[0], setAvatarMenuOpen = _a[1];
    var _b = useState(0), linkIndex = _b[0], setLinkIndex = _b[1];
    var _c = useState(false), searchBar = _c[0], setSearchBar = _c[1];
    var _d = useState(''), wSearchText = _d[0], setWSearchText = _d[1];
    var navigate = useNavigate();
    useEffect(function () {
        setSearchBar(false);
        if (location.pathname == '/reports') {
            setLinkIndex(1);
        }
        else if (location.pathname == '/documentationhub') {
            setLinkIndex(2);
        }
        else if (location.pathname == '/search') {
            setLinkIndex(3);
        }
        else if (location.pathname == '/') {
            setLinkIndex(0);
        }
        else {
            setLinkIndex(-1);
        }
    }, [location.pathname]);
    return (_jsxs("div", __assign({ style: { display: "flex", flexDirection: "column", height: "100vh" } }, { children: [_jsx(PrimaryHeader, { homeURL: "#/", productName: process.env["PRODUCT_NAME"] || "", navContent: _jsxs(PrimaryHeaderTabs, __assign({ selectedIndex: linkIndex }, { children: [_jsx(Link, __assign({ to: "/" }, { children: _jsx(PrimaryHeaderTab, { children: "Home" }) })), (__checkRoleBasedAccess('Header', 'report')) && _jsx(Link, __assign({ to: "/reports" }, { children: _jsx(PrimaryHeaderTab, { children: "Reports" }) })), (__checkRoleBasedAccess('Header', 'documentationHub')) && _jsx(Link, __assign({ to: "/documentationhub" }, { children: _jsx(PrimaryHeaderTab, { children: "Documentation hub" }) }))] })), utilities: _jsxs(_Fragment, { children: [searchBar &&
                            _jsx(Input, { appearance: "auto", append: _jsxs(_Fragment, { children: [_jsx(InputAddon, { icon: Cancel, onClick: function () { setWSearchText(""); setSearchBar(!searchBar); } }), _jsx(InputAddon, { icon: Search, onClick: function () { navigate("search?q=".concat(encodeURIComponent(wSearchText))); } })] }), onKeyDown: function (e) {
                                    if (e.key === 'Enter') {
                                        navigate("search?q=".concat(encodeURIComponent(wSearchText)));
                                    }
                                }, placeholder: "Search by client or product", onChange: function (_a) {
                                    var value = _a.currentTarget.value;
                                    setWSearchText(value);
                                }, value: wSearchText, style: { width: 377, borderRadius: 20 } }), !searchBar && linkIndex != 0 && linkIndex != 4 && _jsx(DiscButton, { onClick: function () { return setSearchBar(!searchBar); }, icon: Search }), _jsx(Popover, __assign({ placement: "bottom-end", open: avatarMenuOpen, onClose: function () { setAvatarMenuOpen(false); }, content: _jsxs(_Fragment, { children: [_jsx(Menu, { children: _jsx(MenuItem, { as: "button", onClick: function () {
                                                auth.signOut();
                                            }, icon: ExitToApp, label: "Sign out" }) }), _jsx(Text, __assign({ as: "p", style: { textAlign: "center", color: "#C1C1C1", fontSize: "12px", padding: "5px", borderTop: "1px solid #EAEAEA" } }, { children: process.env.RELEASE_VERSION }))] }) }, { children: _jsx(Avatar, { name: auth.profile.name, onClick: function () { setAvatarMenuOpen(!avatarMenuOpen); } }) }))] }) }), _jsx("main", __assign({ ref: main, style: { flex: 1, overflowY: "auto" } }, { children: _jsx(Outlet, {}) }))] })));
}
