var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
// import { useNavigate } from "react-router-dom";
import { Card, spacing40, Text } from "@wayfarer/components";
import { useAuth } from "./Auth";
// import { useNavigate } from "react-router-dom";
// import PubSub from "pubsub-js";
// const setAnalyticsDataLayer = require("./lib/analytics-datalayer.js");
// import { useAuth } from "./Auth";
export default function SelectRegion() {
    var auth = useAuth();
    useEffect(function () {
        auth.signOut();
    }, []);
    // const navigate = useNavigate();
    var handleRegion = function (region) {
        if (region === void 0) { region = 'disable'; }
        if (region == 'NAB') {
            window.location.replace("?region=NAB");
            // window.location.hash = `?region=NAB`;
        }
        else if (region == 'EUB') {
            window.location.replace("?region=EUB");
        }
        else {
            window.location.replace("?region=Internal");
            // window.location.hash = "";
        }
    };
    return (_jsx("div", __assign({ style: { marginLeft: spacing40, marginRight: spacing40, height: "100%", width: "100%", textAlign: "center", paddingTop: 175 } }, { children: _jsx(Card, { children: _jsxs("div", __assign({ style: { width: 850, display: "flex", height: 132, padding: 50, flexDirection: "column", justifyContent: "flex-start" } }, { children: [_jsx(Text, __assign({ variant: "boldXL", color: "raspberry600", style: { textAlign: "left" } }, { children: "Please choose the region to access this app" })), _jsxs(Text, __assign({ as: "h3", appearance: "auto", variant: "regularLarge", style: { textAlign: "left", paddingTop: 12 } }, { children: ["North & Latin America users - ", _jsx("span", __assign({ style: { color: "#426da9", cursor: "pointer" }, onClick: function () { return handleRegion('NAB'); } }, { children: "Click Here" }))] })), _jsxs(Text, __assign({ as: "h3", appearance: "auto", variant: "regularLarge", style: { textAlign: "left", paddingTop: 7 } }, { children: ["UK & Ireland users  - ", _jsx("span", __assign({ style: { color: "#426da9", cursor: "pointer" }, onClick: function () { return handleRegion('EUB'); } }, { children: "Click Here" }))] })), _jsxs(Text, __assign({ as: "h3", appearance: "auto", variant: "regularLarge", style: { textAlign: "left", paddingTop: 7 } }, { children: ["Internal users - ", _jsx("span", __assign({ style: { color: "#426da9", cursor: "pointer" }, onClick: function () { return handleRegion(); } }, { children: "Click Here" }))] }))] })) }) })));
}
