var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import { Alert, Text } from "@wayfarer/components";
import useHash from "./useHash";
var ConfigurationError = /** @class */ (function (_super) {
    __extends(ConfigurationError, _super);
    function ConfigurationError(service, message) {
        var _this = _super.call(this, message) || this;
        _this.service = service;
        return _this;
    }
    return ConfigurationError;
}(Error));
export { ConfigurationError };
var OktaConfigurationError = /** @class */ (function (_super) {
    __extends(OktaConfigurationError, _super);
    function OktaConfigurationError(message) {
        return _super.call(this, "Okta", message) || this;
    }
    return OktaConfigurationError;
}(ConfigurationError));
export { OktaConfigurationError };
function deserializeError(details) {
    var d = details;
    if (!d || typeof d !== "object") {
        return;
    }
    var message = d["message"];
    if (message !== "string") {
    }
    var s = d["service"];
    var service = s === "Okta" ? s : undefined;
    return { message: message, service: service };
}
export default function ConfigurationErrorComponent() {
    var _a = useState(undefined), error = _a[0], setError = _a[1];
    var hash = useHash();
    useEffect(function () {
        var qs = parseQueryString(hash);
        if ("e" in qs) {
            try {
                var json = JSON.parse(qs["e"]);
                setError(deserializeError(json));
            }
            catch (_a) {
                setError(undefined);
            }
        }
    }, [hash]);
    var subject = useMemo(function () {
        if (!error) {
            return "UNKNOWN_ERROR";
        }
        if ((error === null || error === void 0 ? void 0 : error.message) === 'ACCESS_DENIED') {
            return "ACCESS_DENIED";
        }
        if (!error.service) {
            return "CONFIG_ERROR";
        }
        return "".concat(error.service, " configuration error");
    }, [error]);
    return (_jsx(_Fragment, { children: _jsxs(Alert, __assign({ motif: "error", layout: "normal", subject: "An unexpected error occurred", style: { width: 620, left: "33.3%", marginTop: "10%" } }, { children: [((error === null || error === void 0 ? void 0 : error.message) === "ACCESS_DENIED") ?
                    _jsxs(_Fragment, { children: ["You don't have access. Request access ", _jsx("a", __assign({ href: "mailto:" + process.env.SUPPORT_EMAIL, style: { position: "relative", color: "#426da9", cursor: "pointer" } }, { children: "here" })), "."] })
                    :
                        _jsxs(_Fragment, { children: ["An unexpected error has occurred. If the problem persists, contact us ", _jsx("a", __assign({ href: "mailto:" + process.env.SUPPORT_EMAIL, style: { position: "relative", color: "#426da9", cursor: "pointer" } }, { children: "here" })), "."] }), _jsxs(Text, __assign({ style: { paddingTop: 20, display: 'block' }, variant: "mediumBase" }, { children: ["Error code: ", subject] }))] })) }));
}
function parseQueryString(loc) {
    var qsix = loc.indexOf("?");
    if (qsix < 0) {
        return {};
    }
    var qs = loc.substring(qsix + 1);
    return Object.fromEntries(qs
        .split("&")
        .map(function (kv) { return kv.split("=").map(function (x, i) { return i ? decodeURIComponent(x) : x; }); }));
}
