var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Text, spacing16, Pagination, spacing4, Dropdown, Divider } from "@wayfarer/components";
var CustomPagination = function (props) {
    var _a = useState(0), totalRows = _a[0], setTotalRows = _a[1];
    useEffect(function () {
        var tr = props.totalItems ? Math.ceil(props.totalItems / props.rowCount) : 0;
        setTotalRows(tr);
    }, [props.totalItems, props.rowCount]);
    return (_jsx(_Fragment, { children: (props.totalItems > 0) &&
            _jsxs("section", __assign({ style: { display: "flex", paddingTop: spacing16, justifyContent: "space-between" } }, { children: [_jsx(Pagination, { style: { maxWidth: 400 }, pageCount: totalRows, pageIndex: props.pageIndex, onPageIndexChange: function (value) { return props.onPageChange(value); } }), props.totalItems &&
                        _jsxs("div", __assign({ style: { display: "flex", justifyContent: "center", alignItems: "center", gap: 12 } }, { children: [_jsx(Text, __assign({ appearance: "auto", variant: "regularBase" }, { children: "Showing ".concat((props.pageIndex * props.rowCount) + 1, " to ").concat((props.pageIndex === totalRows - 1) ? props.totalItems : (props.pageIndex * props.rowCount) + props.rowCount, " of ").concat(props.totalItems) })), _jsx(Divider, { style: { width: 1 }, y: 32 }), _jsxs("div", __assign({ style: { display: "flex", alignItems: "center" } }, { children: [_jsx(Text, __assign({ style: { paddingRight: spacing4 }, appearance: "auto", variant: "regularBase" }, { children: " Rows per page:" })), _jsx(Dropdown, { options: [5, 10, 15, 20, 25], optionToString: function (value) { return "".concat(value); }, value: [props.rowCount], onChange: function (value) { props.updatePageRows(value[0]); } })] }))] }))] })) }));
};
export default CustomPagination;
