var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createContext, useContext, useReducer } from "react";
import { Spinner } from "@wayfarer/components";
var Busy = createContext(function (num) { });
export default function GlobalSpinner(_a) {
    var children = _a.children;
    var _b = useReducer(function (busy, update) { return busy + update; }, 0), busy = _b[0], updateBusy = _b[1];
    return (_jsxs(Busy.Provider, __assign({ value: updateBusy }, { children: [children, _jsx(Spinner, { active: busy > 0 })] })));
}
export function useGlobalSpinner() {
    var update = useContext(Busy);
    var notBusy = function () {
        update(-1);
    };
    var busy = function () {
        update(1);
        return notBusy;
    };
    return {
        busy: busy,
    };
}
