import { useEffect, useState } from "react";
export default function useHash() {
    var _a = useState(window.location.hash), hash = _a[0], setHash = _a[1];
    useEffect(function () {
        var listener = function () { setHash(window.location.hash); };
        window.addEventListener("hashchange", listener);
        return function () {
            window.removeEventListener("hashchange", listener);
        };
    }, []);
    return hash;
}
