var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Text } from "@wayfarer/components";
import LOGO from "./logo-with-text.png";
export default function LoginNAB() {
    // const navigate = useNavigate();
    var handleRegion = function (region) {
        if (region === void 0) { region = 'disable'; }
        if (region == 'NAB') {
            window.location.replace("?region=NAB");
            // window.location.hash = `?region=NAB`;
        }
        else if (region == 'EUB') {
            window.location.replace("?region=EUB");
        }
        else {
            window.location.replace("?region=Internal");
            // window.location.hash = "";
        }
    };
    return (_jsxs("div", __assign({ style: { width: "100%", height: "100vh", display: "flex", flexDirection: "row", justifyContent: "flex-start" } }, { children: [_jsx("div", __assign({ style: { width: "50%", height: "100%", backgroundColor: "#AF1685", display: "flex", justifyContent: "center", alignItems: "center" } }, { children: _jsx("img", { src: LOGO, alt: "My Experian", width: "357px" }) })), _jsx("div", __assign({ style: { width: "50%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" } }, { children: _jsxs("div", __assign({ style: { width: "100%", display: "flex", flexDirection: "column", alignItems: "center" } }, { children: [_jsx(Text, __assign({ variant: "boldXL", color: "raspberry600" }, { children: "MyExperian - North & Latin America" })), _jsx(Button, __assign({ motif: "primary", iconAlignment: "start", disabled: false, style: { marginTop: 15, cursor: "pointer", width: 200 }, onClick: function () { return handleRegion('NAB'); } }, { children: "Sign in" })), _jsxs(Text, __assign({ appearance: "auto", variant: "regularBase", style: { marginTop: 10 } }, { children: ["Problem singing in? ", _jsx(Text, __assign({ as: "a", href: "mailto:" + process.env.SUPPORT_EMAIL, appearance: "auto", variant: "regularBase" }, { children: "Get support" }))] }))] })) }))] })));
}
