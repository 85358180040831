var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Text } from "@wayfarer/components";
var configTxt = require("../../../config-en.json");
var ErrorAssistiveText = function (props) {
    return _jsxs("div", __assign({ style: { display: "flex", justifyContent: "flex-start", alignItems: "center", gap: 4 } }, { children: [_jsx("svg", __assign({ width: "16", height: "16", viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { d: "M8 0.859375C3.86 0.859375 0.5 4.21938 0.5 8.35938C0.5 12.4994 3.86 15.8594 8 15.8594C12.14 15.8594 15.5 12.4994 15.5 8.35938C15.5 4.21938 12.14 0.859375 8 0.859375ZM8.75 12.1094H7.25V10.6094H8.75V12.1094ZM8.75 9.10938H7.25V4.60938H8.75V9.10938Z", fill: "#C60025" }) })), _jsx(Text, __assign({ style: { color: "red" } }, { children: (props === null || props === void 0 ? void 0 : props.text) ? props === null || props === void 0 ? void 0 : props.text : configTxt.REQUIRED_FIELD }))] }));
};
export default ErrorAssistiveText;
